import { createContext } from "react"
import { AttractionNew } from "../../shared/models/AttractionNew"

export const ModalContext = createContext<{
  showModal: boolean
  modalMapItem: AttractionNew | null
  toggleModal: (mapItem: AttractionNew | null) => void
}>({
  showModal: false,
  modalMapItem: null,
  toggleModal: () => {}
})
