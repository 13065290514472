import React, { useEffect, useRef, useState } from "react"
import AdminPhotoGallery from "./AdminPhotoGallery"
import api from "../Api/Api"
import { PhotoModel } from "../../shared/models/PhotoModel"
import { AttractionReviewStatus } from "../Models/Enums/AttractionReviewStatus"
import LoaderAnimation from "./LoaderAnimation"
import { AttractionNew } from "../../shared/models/AttractionNew"
import { useAdminModalStore } from "../Stores/adminModalStore"

interface AdminModalProps {
  onClose: () => void
  onCloseWithRefresh: () => void
  onItemDeleted: (attraction: AttractionNew) => void
  onReviewStatusChanged: (attraction: AttractionNew) => void
  modalMapItem: AttractionNew | null
  totalAttractionsCount: number
}

const AdminModalNew: React.FC<AdminModalProps> = ({
  onClose,
  onCloseWithRefresh,
  onItemDeleted,
  onReviewStatusChanged,
  modalMapItem,
  totalAttractionsCount
}: AdminModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null)

  const [reviewInputText, setReviewInputText] = useState("")

  const [editorialRatingFix, setEditorialRatingFix] = useState(0)

  const [photoModels, setPhotoModels] = useState<PhotoModel[]>([])

  const [detailedData, setDetailedData] = useState<AttractionNew | null>(null)

  const {
    isLoading,
    setModalMapItem,
    setIsLoading,
    setItemIsFeatured,
    setItemIsHiddenGem,
    isFeatured,
    isHiddenGem,
    updateItemIsFeatured,
    updateItemIsHiddenGem
  } = useAdminModalStore(state => ({
    isLoading: state.isLoading,
    setModalMapItem: state.setModalMapItem,
    setIsLoading: state.setIsLoading,
    setItemIsFeatured: state.setItemIsFeatured,
    setItemIsHiddenGem: state.setItemIsHiddenGem,
    isFeatured: state.itemIsFeatured,
    isHiddenGem: state.itemIsHiddenGem,
    updateItemIsFeatured: state.updateItemIsFeatured,
    updateItemIsHiddenGem: state.updateItemIsHiddenGem
  }))

  const handleReviewInputChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setReviewInputText(event.target.value)
  }

  const handleEditorialRatingFixChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setEditorialRatingFix(Number(event.target.value))
  }

  const handleDeleteAttraction = () => {
    if (window.confirm("Are you sure you want to delete this attraction?")) {
      api.delete(`/admin/attraction/${modalMapItem?.id}`).then(response => {
        console.log(response.data)
        if (response.status === 200) {
          alert("Attraction deleted")
          onItemDeleted(modalMapItem!)
          onClose()
        }
      })
    }
  }

  const setEditorialFix = () => {
    setIsLoading(true)
    api
      .post(`/admin/attraction/${modalMapItem?.id}/setRatingFixPercentage`, {
        editorRatingFixPercentage: editorialRatingFix
      })
      .then(response => {
        console.log(response.data)
        if (response.status === 200) {
          onCloseWithRefresh()
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleAssignWikipediaPage = (wikipediaPageUrl: string) => {
    setIsLoading(true)
    api
      .post(`/admin/attraction/${modalMapItem?.id}/setWikipediaPage`, {
        wikipediaPageUrl: wikipediaPageUrl
      })
      .then(response => {
        if (response.status === 200) {
          alert("Wikipedia page updated")
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const setWrongCategory = () => {
    setIsLoading(true)
    if (
      window.confirm(
        "Are you sure you want to set this attraction as the one that requires recategorization?"
      )
    ) {
      api
        .post(`/admin/attraction/${modalMapItem?.id}/setWrongCategory`)
        .then(response => {
          if (response.status === 200) {
            alert("Removed from the current category")
          }
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    if (modalMapItem) {
      setModalMapItem(modalMapItem)
      setIsLoading(true)
      api
        .get(`/admin/attraction/${modalMapItem.id}`)
        .then(response => {
          setDetailedData(response.data)
          setPhotoModels(response.data.photoModels)
          setReviewInputText(response.data.manualReviewComment ?? "")
          setEditorialRatingFix(response.data.editorialRatingFix ?? 0)
          setItemIsFeatured(response.data.isFeatured)
          setItemIsHiddenGem(response.data.isHiddenGem)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }, [modalMapItem])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose()
      }
    }

    // Attach event listener
    document.addEventListener("keydown", handleKeyDown)

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [onClose])

  const loadMoreImages = () => {
    setIsLoading(true)
    api
      .get(`/admin/attraction/${modalMapItem?.id}/loadMoreImages`)
      .then(response => {
        console.log(response.data)
        let images = response.data as PhotoModel[]
        setPhotoModels(images)
      })
      .catch(error => {
        if (error.response) {
          alert(error.response.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const loadGooglePlaceImage = () => {
    setIsLoading(true)
    api
      .get(`/admin/attraction/${modalMapItem?.id}/loadGooglePlacesImage`)
      .then(response => {
        console.log(response.data)
        let images = response.data as PhotoModel[]
        setPhotoModels(images)
      })
      .catch(error => {
        if (error.response) {
          alert(error.response.data)
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleReviewSubmit = (approved: boolean) => {
    // modalMapItem!.manualReviewStatus = approved
    //   ? AttractionReviewStatus.Approved
    //   : AttractionReviewStatus.Rejected

    // modalMapItem!.manualReviewComment = reviewInputText

    api
      .post(`/admin/attraction/${modalMapItem?.id}/setReviewStatus`, {
        id: modalMapItem?.id,
        status: approved ? "Approved" : "Rejected",
        reviewComment: reviewInputText
      })
      .then(response => {
        console.log(response.data)
        if (response.status === 200) {
          alert("Review status updated")
          onReviewStatusChanged(modalMapItem!)
        }
      })
  }

  return (
    <div className="py-2">
      {isLoading ? <LoaderAnimation /> : null}
      <div className="tab-content" id="modal-tabs">
        <div onClick={onClose} className="modal-backdrop fade show"></div>
        <div
          className="modal fade show"
          style={{ display: "block" }}
          ref={modalRef}
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {modalMapItem && modalMapItem.name}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={onClose}
                ></button>
              </div>
              <div className="row">
                <div className="col-10">
                  <div className="modal-body">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="info-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#info"
                          type="button"
                          role="tab"
                          aria-controls="info"
                          aria-selected="true"
                        >
                          Main info
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="images-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#images"
                          type="button"
                          role="tab"
                          aria-controls="images"
                          aria-selected="false"
                        >
                          Images
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="wiki-content-local-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#wiki-local"
                          type="button"
                          role="tab"
                          aria-controls="wiki-local"
                          aria-selected="false"
                        >
                          Wikipedia Local Content
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="wiki-content-en-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#wiki-en"
                          type="button"
                          role="tab"
                          aria-controls="wiki-en"
                          aria-selected="false"
                        >
                          Wikipedia English Content
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content">
                      <div
                        className="tab-pane fade show active"
                        id="info"
                        role="tabpanel"
                        aria-labelledby="info-tab"
                      >
                        {detailedData && modalMapItem && (
                          <>
                            <p>
                              <span>ID:</span> <b>{modalMapItem.id}</b>
                              <br />
                              <span>Wikidata Name:</span> {modalMapItem.name}
                              <br />
                              <span>Wikidata Url:</span>{" "}
                              <a
                                href={modalMapItem.wikidataUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {modalMapItem.wikidataUrl}
                              </a>
                              <br />
                              {detailedData.wikipediaDataList && (
                                <>
                                  <span>Wikipedia:</span>{" "}
                                  <ul>
                                    {detailedData.wikipediaDataList.map(
                                      (item, index) => (
                                        <li key={index}>
                                          <span>Title:</span>{" "}
                                          <b>{item.title}</b>
                                          <br />
                                          <span>URL:</span>{" "}
                                          <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            {item.url}
                                          </a>
                                          <br />
                                          <span>Number of Views:</span>{" "}
                                          <b>{item.numberOfViews}</b>
                                          <br />
                                          <span>Is Local:</span>{" "}
                                          <b>{item.isLocal ? "Yes" : "No"}</b>
                                          <br />
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </>
                              )}
                              <br />
                              <span>Categories:</span>{" "}
                              <ul>
                                {detailedData.categories.map(
                                  (category, index) => (
                                    <li key={index}>
                                      <span>{category.name}</span>
                                    </li>
                                  )
                                )}
                              </ul>
                              <span>Image Id:</span>{" "}
                              {modalMapItem.miniPhotoUrl.split("/").pop()}
                              <br />
                              <br />
                              <span>Total interest score:</span>{" "}
                              {modalMapItem.countryFinalRating}%
                              <br />
                              <span>Significane score (1-5):</span>{" "}
                              {modalMapItem.countryFinalStarScore} ⭐
                              <br />
                              <span>Rating calculated:</span>{" "}
                              {modalMapItem.countryRating}%
                              <br />
                              <span>AI score:</span>{" "}
                              {modalMapItem.syntheticScore}%
                              <br />
                            </p>
                            {/* <p>
                              <a href={modalMapItem.googlePlacesUrl}>
                                Google Maps Url
                              </a>{" "}
                              -{" "}
                              {decodeURIComponent(modalMapItem.googlePlacesUrl)}
                              <br></br>
                              <a href={modalMapItem.wikipediaUrl}>
                                Wikipedia Url
                              </a>{" "}
                              - {modalMapItem.wikipediaUrl}
                              <br></br>
                              <a href={modalMapItem.wikipediaUrlEn}>
                                Wikipedia Url En
                              </a>{" "}
                              - {modalMapItem.wikipediaUrlEn}
                              <br></br>
                              {modalMapItem &&
                                modalMapItem.wikipediaPageCandidates && (
                                  <>
                                    <h5>Wikipedia Page Candidates</h5>
                                    <ul>
                                      {modalMapItem.wikipediaPageCandidates.map(
                                        (candidate, index) => (
                                          <li key={index}>
                                            <a
                                              href={candidate}
                                              target="_blank"
                                              rel="noopener noreferrer"
                                            >
                                              {candidate}
                                            </a>{" "}
                                            <button
                                              onClick={() =>
                                                handleAssignWikipediaPage(
                                                  candidate
                                                )
                                              }
                                            >
                                              Assign
                                            </button>
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  </>
                                )}
                            </p> */}

                            <h5>Coordinates</h5>
                            <p>
                              {modalMapItem.latitude}, {modalMapItem.longitude}
                            </p>

                            {/* <h5>Other</h5>
                            <p>
                              <span>Categories Local:</span>{" "}
                              
                              <br></br>
                            </p> */}
                          </>
                        )}

                        <h5>Description</h5>
                        <p>{modalMapItem && modalMapItem.description}</p>
                      </div>

                      <div
                        className="tab-pane fade"
                        id="images"
                        role="tabpanel"
                        aria-labelledby="images-tab"
                      >
                        <h5>Images</h5>

                        <div className="container">
                          <div className="row">
                            {modalMapItem && photoModels && (
                              <AdminPhotoGallery imageUrls={photoModels} />
                            )}
                          </div>
                        </div>
                        {/* <iframe
                      className="images-iframe"
                      src={modalMapItem?.details.bingImagesUrl}
                      title="description"
                    ></iframe> */}
                      </div>

                      <div
                        className="tab-pane fade"
                        id="wiki-local"
                        role="tabpanel"
                        aria-labelledby="wiki-content-local-tab"
                      >
                        <p>
                          {/* {modalMapItem &&
                            modalMapItem.wikipediaDescriptionLocal} */}
                        </p>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="wiki-en"
                        role="tabpanel"
                        aria-labelledby="wiki-content-en-tab"
                      >
                        <p>
                          {/* {modalMapItem && modalMapItem.wikipediaDescriptionEn} */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <div className="m-4">
                    <h5>Attraction Controls</h5>
                    <button
                      className="btn btn-danger w-100 mb-2"
                      onClick={() => handleDeleteAttraction()}
                    >
                      Delete
                    </button>
                    <button
                      className="btn btn-primary w-100 mb-2"
                      onClick={() => {
                        handleReviewSubmit(true)
                      }}
                    >
                      Set as reviewed
                    </button>
                    <button className="btn btn-outline-primary w-100 mb-2">
                      Set as incorrect wikipedia
                    </button>
                    <button
                      className="btn btn-outline-primary w-100 mb-2"
                      onClick={() => loadMoreImages()}
                    >
                      Load more images
                    </button>
                    <button
                      className="btn btn-outline-primary w-100 mb-2"
                      onClick={() => loadGooglePlaceImage()}
                    >
                      Load Google Places images (*expensive)
                    </button>
                    <button className="btn btn-outline-primary w-100 mb-2">
                      Set as requires re-ranking
                    </button>
                    <button
                      className="btn btn-outline-primary w-100 mb-2"
                      onClick={() => setWrongCategory()}
                    >
                      Set as wrong category
                    </button>

                    <input
                      type="number"
                      className="form-control mb-2"
                      value={editorialRatingFix}
                      onChange={handleEditorialRatingFixChange}
                      placeholder="Editorial fix..."
                    />

                    <button
                      className="btn btn-outline-primary w-100 mb-2"
                      onClick={() => setEditorialFix()}
                    >
                      Apply editorial rating fix in %
                    </button>

                    <div className="form-check form-switch mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isFeatured"
                        checked={isFeatured}
                        onChange={() => updateItemIsFeatured(!isFeatured)}
                      />
                      <label className="form-check-label" htmlFor="isFeatured">
                        Is featured
                      </label>
                    </div>

                    <div className="form-check form-switch mb-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="isHiddenGem"
                        checked={isHiddenGem}
                        onChange={() => updateItemIsHiddenGem(!isHiddenGem)}
                      />
                      <label className="form-check-label" htmlFor="isHiddenGem">
                        Is hidden gem
                      </label>
                    </div>

                    <textarea
                      className="form-control mb-2"
                      value={reviewInputText}
                      onChange={handleReviewInputChange}
                      rows={3}
                      placeholder="Enter rejection reason here"
                    ></textarea>

                    <button
                      className="btn btn-warning w-100"
                      onClick={() => {
                        handleReviewSubmit(false)
                      }}
                    >
                      Reject review
                    </button>
                  </div>
                </div>
              </div>

              <div className="modal-footer">
                <button
                  onClick={onClose}
                  type="button"
                  className="btn btn-outline-secondary"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminModalNew
