import { create } from "zustand"

interface FilterViewState {
  isFreeMapSearch: boolean
  setIsFreeMapSearch: (isFreeMapSearch: boolean) => void

  numberOfItemsToLoad: number
  setNumberOfItemsToLoad: (numberOfItemsToLoad: number) => void

  isFeaturedItem: boolean
  setIsFeaturedItem: (isFeaturedItem: boolean) => void

  isHiddenGem: boolean
  setIsHiddenGem: (isHiddenGem: boolean) => void
}
export const useFilterViewStore = create<FilterViewState>(set => ({
  isFreeMapSearch: false,
  numberOfItemsToLoad: 150,
  isFeaturedItem: false,
  isHiddenGem: false,
  setNumberOfItemsToLoad: (numberOfItemsToLoad: number) => {
    set({ numberOfItemsToLoad })
  },
  setIsFreeMapSearch: (isFreeMapSearch: boolean) => {
    set({ isFreeMapSearch })
  },
  setIsFeaturedItem: (isFeaturedItem: boolean) => {
    set({ isFeaturedItem })
  },
  setIsHiddenGem: (isHiddenGem: boolean) => {
    set({ isHiddenGem })
  }
}))
