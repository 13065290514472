import React, { useEffect, useRef, useState } from "react"

interface Props {
  items: string[]
  onValueChange: (value: string) => void
}

const TextDropdown: React.FC<Props> = ({ items, onValueChange }) => {
  const [selectedValue, setSelectedValue] = useState<string>("")

  const customValueRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    setSelectedValue(items[0])
    handleClick(items[0])
  }, [])

  const handleClick = (value: string) => {
    console.log("handleClick")
    setSelectedValue(value)
    onValueChange(value)
  }

  const handleCustomValueClick = () => {
    console.log("handleCustomValueClick")
    if (customValueRef.current?.value != null) {
      setSelectedValue(customValueRef.current?.value)
      onValueChange(customValueRef.current?.value)
    }
  }

  return (
    <div className="dropdown m-1">
      <span
        className="dropdownSpan"
        id="dropdownMenuButton"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {selectedValue}
      </span>
      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
        <li>
          <div className="form-group px-2 py-2">
            <input
              ref={customValueRef}
              type="text"
              className="form-control"
              placeholder="Any value"
            />
            <button
              onClick={handleCustomValueClick}
              className="btn btn-outline-primary mt-2 "
            >
              Ok
            </button>
          </div>
        </li>
        {items.map((item, index) => (
          <li
            className="dropdown-item"
            onClick={() => handleClick(item)}
            key={index}
          >
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default TextDropdown
