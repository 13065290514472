import React, { ReactNode } from "react"
import Navbar from "./Navbar"
import Footer from "./Footer"

type AuthenticatedLayoutProps = {
  children: ReactNode
}

const AuthenticatedLayout: React.FC<AuthenticatedLayoutProps> = ({
  children
}) => {
  const links = [
    { name: "Home", path: "/" },
    { name: "About", path: "/about" },
    { name: "Contact", path: "/contact" },
    { name: "Admin", path: "/admin" },
    { name: "Logs", path: "/logs" }
  ]

  return (
    <div>
      <Navbar title="AI Maps" links={links} />
      <div className="container-fluid">
        <main role="main" className="pb-3">
          {children}
        </main>
      </div>
      <Footer companyName="AI Maps" />
    </div>
  )
}

export default AuthenticatedLayout
