import { createContext } from "react"
import { MapFilterValues } from "../Models/MapFilterValues"

export const MapFilterValuesContext = createContext<{
  mapFilterValues: MapFilterValues
  setMapFilterValues: (filterValues: MapFilterValues) => void
}>({
  mapFilterValues: { showUnreviewedOnly: false, showRejectedOnly: false },
  setMapFilterValues: () => {}
})
