import React, { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { Log } from "../Models/Log"
import api from "../Api/Api"
import { LogResponse } from "../Models/LogResponse"

const LogViewer: React.FC = () => {
  const [logs, setLogs] = useState<Log[]>([])
  const [page, setPage] = useState<number>(1)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    fetchLogs(page)
  }, [page])

  const fetchLogs = async (currentPage: number): Promise<void> => {
    api
      .get<LogResponse>(`/admin/logs?page=${currentPage}&pageSize=100`)
      .then(response => {
        setLogs(prevLogs => [...prevLogs, ...response.data.logs])
        setHasMore(response.data.hasMore)
      })
      .catch(error => {
        console.error("Fetching logs failed:", error)
        setHasMore(false)
      }) // Prevent further attempts if there's an error})
  }
  return (
    <InfiniteScroll
      dataLength={logs.length}
      next={() => setPage(prevPage => prevPage + 1)}
      hasMore={hasMore}
      loader={<div className="text-center mt-2">Loading...</div>}
      endMessage={
        <p className="text-center mt-2">
          <b>You have seen it all</b>
        </p>
      }
    >
      <div>
        <h2 className="text-center my-4">Logs</h2>
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Timestamp</th>
                <th>Level</th>
                <th>Message</th>
                <th>Attraction Name</th>
                <th>Operation</th>
              </tr>
            </thead>
            <tbody>
              {logs.map(log => (
                <tr key={log.id}>
                  <td>{new Date(log.timestamp).toLocaleString()}</td>
                  <td>{log.level}</td>
                  <td>{log.message}</td>
                  <td>{log.attractionName}</td>
                  <td>{log.operation}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default LogViewer
