import React, { useContext, useEffect, useState } from "react"
import { Attraction } from "../Models/Attraction"
import { ModalContext } from "../Contexts/ModalContext"
import { MapHoverLinkContext } from "../Contexts/MapHoverLinkContext"
import "bootstrap-icons/font/bootstrap-icons.css"
import { AttractionReviewStatus } from "../Models/Enums/AttractionReviewStatus"
import { MapFilterValuesContext } from "../Contexts/MapFilterValuesContext"
import { AttractionNew } from "../../shared/models/AttractionNew"
import { LazyLoadImage } from "react-lazy-load-image-component"

interface Props {
  items: AttractionNew[]
  updateReviewedStats: boolean
  onItemBookmarked: (index: number) => void
}

const AnswersList: React.FC<Props> = ({
  items,
  updateReviewedStats,
  onItemBookmarked
}) => {
  useEffect(() => {
    //items.map(item => console.log(item.name))
  }, [items])

  const { toggleModal } = useContext(ModalContext)
  const { setHoveredMapItem } = useContext(MapHoverLinkContext)
  const { setClickedMapItem } = useContext(MapHoverLinkContext)
  const { setMapFilterValues } = useContext(MapFilterValuesContext)
  //const [data, setData] = useState<MapItem[]>(items)

  const [reviewStats, setReviewStats] = useState({
    total: 0,
    reviewed: 0,
    percentage: 0
  })

  useEffect(() => {
    const total = items.length
    const reviewed = 0
    // TODO: NEW
    // const reviewed = items.filter(
    //   item =>
    //     item.manualReviewStatus === AttractionReviewStatus.Approved ||
    //     item.manualReviewStatus === AttractionReviewStatus.Rejected
    // ).length
    const percentage = total > 0 ? (reviewed / total) * 100 : 0

    setReviewStats({ total, reviewed, percentage })
  }, [updateReviewedStats, items])

  const handleClick = (item: AttractionNew) => {
    toggleModal(item)
  }

  const [searchTerm, setSearchTerm] = useState("")
  const [showUnreviewedOnly, setShowUnreviewedOnly] = useState(Boolean)
  const [showRejectedOnly, setShowRejectedOnly] = useState(Boolean)

  const handleMouseOver = (item: AttractionNew, setHovering: boolean) => {
    if (setHovering) {
      setHoveredMapItem(item)
      console.log("Mouse over" + item.name)
    } else {
      setHoveredMapItem(null)
    }
  }

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value.toLowerCase())
  }

  const handleUnreviewedOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowUnreviewedOnly(event.target.checked)
    setMapFilterValues({
      showUnreviewedOnly: event.target.checked,
      showRejectedOnly: showRejectedOnly
    })
  }

  const handleRejectedOnlyChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowRejectedOnly(event.target.checked)
    setMapFilterValues({
      showUnreviewedOnly: showUnreviewedOnly,
      showRejectedOnly: event.target.checked
    })
  }

  // TODO: new
  // const filteredItems = items.filter(
  //   item =>
  //     item.name.toLowerCase().includes(searchTerm) &&
  //     (showUnreviewedOnly
  //       ? item.manualReviewStatus === AttractionReviewStatus.Pending
  //       : true) &&
  //     (showRejectedOnly
  //       ? item.manualReviewStatus === AttractionReviewStatus.Rejected
  //       : true)
  // )

  return (
    <div className="col-md-12">
      <h3 className="text-center display-10">
        <span className="">Places</span>
      </h3>

      <div className="mb-3">
        <p>
          Reviewed Attractions: {reviewStats.reviewed} / {reviewStats.total} (
          {reviewStats.percentage.toFixed(2)}%)
        </p>
      </div>

      <div className="mb-3">
        <input
          type="text"
          className="form-control"
          placeholder="Search by name"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={showUnreviewedOnly}
          onChange={handleUnreviewedOnlyChange}
          id="only-unreviewed-checkbox"
        />
        <label className="form-check-label" htmlFor="only-unreviewed-checkbox">
          Show not reviewed only
        </label>
      </div>

      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          checked={showRejectedOnly}
          onChange={handleRejectedOnlyChange}
          id="only-rejected-checkbox"
        />
        <label className="form-check-label" htmlFor="only-rejected-checkbox">
          Show rejected only
        </label>
      </div>

      <br />

      <div className="">
        {/* TODO: NEW */}
        {/* {filteredItems.map((item, index) => ( */}
        {items.map((item, index) => (
          <div
            key={index}
            className="h-75 card mb-2"
            onClick={() => {
              handleClick(item)
            }}
          >
            <div className="">
              <div
                className="card-body fixed-height-card"
                onMouseOver={() => handleMouseOver(item, true)}
                onMouseOut={() => handleMouseOver(item, false)}
              >
                <div className="text-center mb-3">
                  <h6 className="card-title">{item.name} </h6>
                </div>
                <div className="row g-0">
                  <div className="col-md-6">
                    <LazyLoadImage
                      alt={item.name}
                      src={`${item.miniPhotoUrl}`}
                      className="card-img-top"
                    />
                    {/* <img
                      src={`${process.env.REACT_APP_API_ENDPOINT}${item.miniPhotoUrl}`}
                      alt={item.name}
                      className="card-img-top"
                    /> */}
                  </div>
                  <div className="col-md-6">
                    <div className="">
                      <ul className="list-group list-group-flush">
                        <li className="list-group-item answer-list-item py-1 text-sm fw-light">
                          Rank: {item.categoryRatingRank.toString()}
                        </li>
                        <li className="list-group-item answer-list-item py-1 text-sm fw-light">
                          Total interest score:{" "}
                          {item.countryFinalRating.toString()}%
                        </li>

                        <li className="list-group-item answer-list-item py-1 text-sm fw-light">
                          Significance: {item.countryFinalStarScore.toString()}{" "}
                          ⭐
                        </li>

                        <li className="list-group-item answer-list-item py-1 text-sm fw-light">
                          Rating calculated: {item.countryRating.toString()}
                        </li>

                        <li className="list-group-item answer-list-item py-1 text-sm fw-light">
                          AI rating: {item.syntheticScore.toString()}
                        </li>

                        <li
                          style={{ cursor: "pointer" }}
                          className="list-group-item answer-list-item py-1 small text-sm fw-light"
                          onClick={e => {
                            e.stopPropagation()
                            setClickedMapItem(item)
                          }}
                        >
                          Show on map
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <button
                  onMouseOver={() => handleMouseOver(item, true)}
                  onMouseOut={() => handleMouseOver(item, false)}
                  onClick={() => {
                    handleClick(item)
                  }}
                  className="btn btn-outline-primary btn-answer-list w-100"
                >
                 
                </button> */}

                {/* <button
                  onClick={() => {
                    onItemBookmarked(index)
                  }}
                  className={`${
                    item.bookmarked != null && item.bookmarked
                      ? "btn-secondary"
                      : "btn-outline-secondary"
                  } btn btn-bookmark`}
                >
                  <i
                    className="bi bi-bookmark-plus-fill"
                    style={{ cursor: "pointer" }}
                  ></i>
                </button> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AnswersList
