import { createContext } from "react"
import { AttractionNew } from "../../shared/models/AttractionNew"

export const MapHoverLinkContext = createContext<{
  hoveredMapItem: AttractionNew | null
  clickedMapItem: AttractionNew | null
  setHoveredMapItem: (mapItem: AttractionNew | null) => void
  setClickedMapItem: (mapItem: AttractionNew | null) => void
}>({
  hoveredMapItem: null,
  clickedMapItem: null,
  setHoveredMapItem: () => {},
  setClickedMapItem: () => {}
})
