import { GoogleLogin } from "@react-oauth/google"
import React from "react"
import { useNavigate } from "react-router-dom" // Import useHistory hook
import api from "../Api/Api"
import { useAuth } from "../Contexts/AuthContext"

const Auth: React.FC = () => {
  const { setIsAuthenticated } = useAuth()
  const navigate = useNavigate() // Initialize useHistory hook

  const getJwtToken = (credential: string | undefined) => {
    api
      .post(`/auth/signin-google`, {
        idToken: credential
      })
      .then(response => {
        console.log(response.data)
        setIsAuthenticated(true)
        api.setAuthToken(response.data)
        navigate("/admin")
      })
      .catch(error => {
        if (error.response) {
          alert(error.response.data)
        }
      })
      .finally(() => {})
  }

  return (
    <div>
      <GoogleLogin
        onSuccess={credentialResponse => {
          console.log(credentialResponse)
          getJwtToken(credentialResponse.credential)
        }}
        onError={() => {
          console.log("Login Failed")
        }}
      />
    </div>
  )
}

export default Auth
