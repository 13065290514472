import React from "react"
import Auth from "./Auth"

const LoginPage: React.FC = () => {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Auth />
    </div>
  )
}

export default LoginPage
