import { create } from "zustand"
import { AttractionNew } from "../../shared/models/AttractionNew"
import api from "../Api/Api"

interface AdminModalState {
  modalMapItem: AttractionNew | null
  setModalMapItem: (modalMapItem: AttractionNew) => void

  isLoading: boolean
  setIsLoading: (isLoading: boolean) => void

  updateItemIsFeatured: (isFeatured: boolean) => void
  updateItemIsHiddenGem: (isHiddenGem: boolean) => void

  itemIsFeatured: boolean
  setItemIsFeatured: (itemIsFeatured: boolean) => void

  itemIsHiddenGem: boolean
  setItemIsHiddenGem: (itemIsHiddenGem: boolean) => void
}
export const useAdminModalStore = create<AdminModalState>((set, get) => ({
  modalMapItem: null,
  isLoading: false,
  itemIsFeatured: false,
  itemIsHiddenGem: false,
  setIsLoading: (isLoading: boolean) => set({ isLoading }),
  setModalMapItem: (modalMapItem: AttractionNew) => set({ modalMapItem }),
  updateItemIsFeatured: (isFeatured: boolean) => {
    const { modalMapItem } = get()
    if (!modalMapItem) return
    api
      .post(`/admin/attraction/${modalMapItem?.id}/setIsFeatured`, {
        id: modalMapItem?.id,
        isFeatured: isFeatured
      })
      .then(response => {
        console.log(response.data)
        set({ itemIsFeatured: isFeatured })
        if (response.status !== 200) {
          alert("Featured status not updated!")
        }
      })
  },
  updateItemIsHiddenGem: (isHiddenGem: boolean) => {
    const { modalMapItem } = get()
    if (!modalMapItem) return
    api
      .post(`/admin/attraction/${modalMapItem?.id}/setIsHiddenGem`, {
        id: modalMapItem?.id,
        isHiddenGem: isHiddenGem
      })
      .then(response => {
        console.log(response.data)
        set({ itemIsHiddenGem: isHiddenGem })
        if (response.status !== 200) {
          alert("Hidden gem status not updated!")
        }
      })
  },
  setItemIsFeatured: (itemIsFeatured: boolean) => set({ itemIsFeatured }),
  setItemIsHiddenGem: (itemIsHiddenGem: boolean) => set({ itemIsHiddenGem })
}))
