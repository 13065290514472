import React from "react"
import { Link } from "react-router-dom"
import { googleLogout } from "@react-oauth/google"
import api from "../Api/Api"
import { useAuth } from "../Contexts/AuthContext"

interface NavbarProps {
  title: string
  links: { name: string; path: string }[]
}

const Navbar: React.FC<NavbarProps> = ({ title, links }) => {
  const { setIsAuthenticated } = useAuth()

  const handleLogout = () => {
    googleLogout()
    setIsAuthenticated(false)
    api.setAuthToken(null)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container">
        <Link className="navbar-brand" to="/">
          {title}
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            {links.map(link => (
              <li className="nav-item" key={link.name}>
                <Link className="nav-link" to={link.path}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <button
          className="btn btn-outline-primary w-10 mb-2"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>
    </nav>
  )
}

export default Navbar
