import React, {
  useState,
  createContext,
  useContext,
  ReactNode,
  useEffect
} from "react"
import api from "../Api/Api"

type AuthContextType = {
  isAuthenticated: boolean
  setIsAuthenticated: (value: boolean) => void
  isLoading: boolean
}

const AuthContext = createContext<AuthContextType | null>(null)

type AuthProviderProps = {
  children: ReactNode
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const storedToken = localStorage.getItem("jwtToken")
    if (storedToken) {
      console.log("Setting token: " + storedToken)
      api.setAuthToken(storedToken)
      console.log("Token set. User is authenticated.")
      setIsAuthenticated(true)
    } else {
      console.log("No token found. User is not authenticated.")
      setIsAuthenticated(false)
    }
    setIsLoading(false)
  }, [])

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, isLoading }}
    >
      {!isLoading ? children : <div>Loading...</div>}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider")
  }
  return context
}
