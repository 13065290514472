import React, { useEffect, useRef } from "react"

interface LoaderAnimationCircleProps {
  isLoading: boolean
}

const LoaderAnimationCircle: React.FC<LoaderAnimationCircleProps> = ({
  isLoading
}) => {
  const circleRef = useRef<SVGCircleElement | null>(null)

  useEffect(() => {
    if (isLoading) {
      const element = circleRef.current
      if (element) {
        element.setAttribute("stroke-dashoffset", "282.6")
        element.style.animation = "none"
        void element.getBBox() // Trigger a reflow
        element.style.animation = ""
      }
    }
  }, [isLoading])

  if (!isLoading) {
    return null // Render nothing when not loading
  }

  return (
    <div
      style={{
        position: "absolute",
        top: "80%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        pointerEvents: "none",
        zIndex: 1000
      }}
    >
      <svg viewBox="0 0 100 100" width="100" height="100">
        <circle
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="#ddd"
          strokeWidth="10"
        />
        <circle
          ref={circleRef}
          cx="50"
          cy="50"
          r="45"
          fill="none"
          stroke="grey"
          strokeWidth="10"
          strokeDasharray="282.6"
          strokeDashoffset="282.6"
        >
          <animate
            attributeName="stroke-dashoffset"
            from="282.6"
            to="0"
            dur="1s"
            fill="freeze"
          />
        </circle>
      </svg>
    </div>
  )
}

export default LoaderAnimationCircle
