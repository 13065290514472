import React, { useEffect, useRef, useState } from "react"
import FilterPanelNew from "./FilterPanelNew"
import api from "../Api/Api"
import AnswersList from "./AnswersList"
import MapBoxView from "./MapBoxView"
import { MapHoverLinkContext } from "../Contexts/MapHoverLinkContext"
import { ModalContext } from "../Contexts/ModalContext"
import LoaderAnimation from "./LoaderAnimation"
import { MapFilterValues } from "../Models/MapFilterValues"
import { MapFilterValuesContext } from "../Contexts/MapFilterValuesContext"
import AdminTools from "./AdminTools"
import { AdminToolsModalContext } from "../Contexts/AdminToolsModalContext"
import AdminToolsModal from "./AdminToolsModal"
import { AttractionNew } from "../../shared/models/AttractionNew"
import AdminModalNew from "./AdminModalNew"
import { Coordinates } from "../../shared/models/Coordinates"
import LoaderAnimationCircle from "./LoaderAnimationCircle"
import { AttractionSignificanceLayer } from "../Models/Enums/AttractionSignificanceLayer"
import { useMapViewStore } from "../Stores/mapViewStore"
import { useFilterViewStore } from "../Stores/filterViewStore"

const AdminPage: React.FC = () => {
  const [mapItems, setData] = useState<AttractionNew[]>([])
  const [reRenderMap, setReRenderMap] = useState<boolean>(false)
  const [flyToCenterMap, setFlyToCenterMap] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isLoadingCircle, setIsLoadingCircle] = useState<boolean>(false)
  const [hoveredMapItem, setHoveredMapItem] = useState<AttractionNew | null>(
    null
  )
  const [clickedMapItem, setClickedMapItem] = useState<AttractionNew | null>(
    null
  )

  const [showModal, setShowModal] = useState(false)
  const [showAdminToolsModal, setShowAdminToolsModal] = useState(false)
  const [loadingCircleTrigger, setLoadingCircleTrigger] =
    useState<boolean>(false)

  const [locationIds, setLocationIds] = useState<string[]>([])
  const [categoryIds, setCategoryIds] = useState<string[]>([])

  const { isFreeMapSearch, numberOfItemsToLoad, isFeaturedItem, isHiddenGem } =
    useFilterViewStore(state => ({
      isFreeMapSearch: state.isFreeMapSearch,
      numberOfItemsToLoad: state.numberOfItemsToLoad,
      isFeaturedItem: state.isFeaturedItem,
      isHiddenGem: state.isHiddenGem
    }))

  const [modalMapItem, setModalMapItem] = useState<AttractionNew | null>(null)
  const [updateListOfItemsReviewedStats, setUpdateListOfItemsReviewedStats] =
    useState<boolean>(false)

  const [mapFilterValues, setMapFilterValues] = useState<MapFilterValues>({
    showUnreviewedOnly: false,
    showRejectedOnly: false
  })

  const [southWestBound, setSouthWestBound] = useState<Coordinates | null>(null)
  const [northEastBound, setNorthEastBound] = useState<Coordinates | null>(null)
  const [zoomLevel, setZoomLevel] = useState<number | null>(null)

  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [requiresReview, setRequiresReview] = useState<boolean | null>(null)
  const [requiresWikipediaReview, setRequiresWikipediaReview] = useState<
    boolean | null
  >(null)

  const [containsWikipediaDuplicates, setContainsWikipediaDuplicates] =
    useState<boolean | null>(null)
  const [withoutWikipediaPages, setWithoutWikipediaPages] = useState<
    boolean | null
  >(null)
  const [minSignificanceLayer, setMinSignificanceLayer] =
    useState<AttractionSignificanceLayer>(
      AttractionSignificanceLayer.MinimalSignificance
    )

  const toggleModal = (mapItem: AttractionNew | null) => {
    setModalMapItem(mapItem)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setModalMapItem(null)
  }

  const closeAdminToolsModal = () => {
    setShowAdminToolsModal(false)
  }

  const toggleAdminToolsModal = () => {
    setShowAdminToolsModal(true)
  }

  const reRenderMapMarkers = (flyToCenter: boolean) => {
    console.log("reRenderMapMarkers")
    setFlyToCenterMap(flyToCenter)
    setReRenderMap(!reRenderMap)
  }

  const handleItemDelete = (mapItem: AttractionNew) => {
    setData(mapItems.filter(item => item.id !== mapItem.id))
    reRenderMapMarkers(false)
    closeModal()
  }

  const handleReviewStatusChanged = (mapItem: AttractionNew) => {
    reRenderMapMarkers(false)
    setUpdateListOfItemsReviewedStats(!updateListOfItemsReviewedStats)
    closeModal()
  }

  const onCloseWithRefresh = () => {
    closeModal()
    handleSubmit(
      locationIds,
      categoryIds,
      null,
      null,
      null,
      null,
      AttractionSignificanceLayer.MinimalSignificance,
      null,
      null,
      null
    )
  }

  useEffect(() => {
    if (northEastBound && southWestBound && zoomLevel !== null) {
      if (isFreeMapSearch) {
        updatePlacesOnMove()
      }
      //setLoadingCircleTrigger(!loadingCircleTrigger)
    }
  }, [northEastBound, southWestBound, zoomLevel])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setIsLoadingCircle(true)

    timeoutRef.current = setTimeout(() => {
      // handleSubmit(
      //   locationIds,
      //   categoryIds,
      //   requiresReview,
      //   requiresWikipediaReview,
      //   containsWikipediaDuplicates,
      //   withoutWikipediaPages,
      //   minSignificanceLayer,
      //   northEastBound,
      //   southWestBound,
      //   zoomLevel
      // );
      setIsLoadingCircle(false)
    }, 2000)

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [loadingCircleTrigger]) // Reset the timer when loadingTrigger changes

  const handleSubmit = (
    locationIds: string[],
    categoryIds: string[],
    requiresReview: boolean | null,
    requiresWikipediaReview: boolean | null,
    containsWikipediaDuplicates: boolean | null,
    withoutWikipediaPages: boolean | null,
    minSignificanceLayer: AttractionSignificanceLayer,
    northEastBound: Coordinates | null,
    southWestBound: Coordinates | null,
    zoomLevel: number | null
  ) => {
    setCategoryIds(categoryIds)
    setLocationIds(locationIds)

    const encodeArray = (key: string, array: string[]): string =>
      array
        .map(item => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`)
        .join("&")

    let queryToSend = ""

    // Append countryIds and categoryIds as arrays
    if (locationIds.length > 0) {
      queryToSend += encodeArray("locationId", locationIds)
    }

    if (categoryIds.length > 0) {
      if (queryToSend) queryToSend += "&"
      queryToSend += encodeArray("categoryId", categoryIds)
    }

    if (northEastBound && southWestBound) {
      if (queryToSend) queryToSend += "&"
      queryToSend += `northEastBound.Latitude=${encodeURIComponent(
        northEastBound.latitude
      )}&northEastBound.Longitude=${encodeURIComponent(
        northEastBound.longitude
      )}&southWestBound.Latitude=${encodeURIComponent(
        southWestBound.latitude
      )}&southWestBound.Longitude=${encodeURIComponent(
        southWestBound.longitude
      )}`
    }

    if (zoomLevel !== null) {
      if (queryToSend) queryToSend += "&"
      queryToSend += `zoomLevel=${encodeURIComponent(zoomLevel)}`
    }

    console.log("requiresReview", requiresReview)

    // Append requiresReview if it's not null
    if (requiresReview !== null) {
      queryToSend += `&requiresReview=${encodeURIComponent(requiresReview)}`
    }

    // Append requiresWikipediaReview if it's not null
    if (requiresWikipediaReview !== null) {
      queryToSend += `&requiresWikipediaReview=${encodeURIComponent(
        requiresWikipediaReview
      )}`
    }

    // Append containsWikipediaDuplicates if it's not null
    if (containsWikipediaDuplicates !== null) {
      queryToSend += `&containsWikipediaDuplicates=${encodeURIComponent(
        containsWikipediaDuplicates
      )}`
    }

    if (withoutWikipediaPages !== null) {
      queryToSend += `&withoutWikipediaPages=${encodeURIComponent(
        withoutWikipediaPages
      )}`
    }

    // Append minSignificanceLayer if it's not null
    if (minSignificanceLayer) {
      queryToSend += `&minSignificanceLayer=${encodeURIComponent(
        minSignificanceLayer
      )}`
    }

    queryToSend += `&isFreeMapSearch=${encodeURIComponent(isFreeMapSearch)}`
    queryToSend += `&numberOfItems=${encodeURIComponent(numberOfItemsToLoad)}`
    queryToSend += `&isFeaturedItem=${encodeURIComponent(isFeaturedItem)}`
    queryToSend += `&isHiddenGemItem=${encodeURIComponent(isHiddenGem)}`

    console.log(queryToSend)

    api
      .get<AttractionNew[]>(`/admin/queryNew?${queryToSend}`)
      .then(response => {
        setData(response.data)
        reRenderMapMarkers(true)
      })
      .catch(error => {
        console.error(error)
        alert(error)
      })
  }

  const updatePlacesOnMove = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setIsLoadingCircle(false) // Stop previous loading
    setTimeout(() => setIsLoadingCircle(true), 0) // Start new loading

    timeoutRef.current = setTimeout(() => {
      handleSubmit(
        locationIds,
        categoryIds,
        requiresReview,
        requiresWikipediaReview,
        containsWikipediaDuplicates,
        withoutWikipediaPages,
        minSignificanceLayer,
        northEastBound,
        southWestBound,
        zoomLevel
      )
      setIsLoadingCircle(false)
    }, 1000)
  }

  return (
    <div className="row">
      {isLoading ? <LoaderAnimation /> : null}
      <MapHoverLinkContext.Provider
        value={{
          hoveredMapItem,
          setHoveredMapItem,
          clickedMapItem,
          setClickedMapItem
        }}
      >
        <MapFilterValuesContext.Provider
          value={{ mapFilterValues, setMapFilterValues }}
        >
          <ModalContext.Provider
            value={{ showModal, modalMapItem, toggleModal }}
          >
            <AdminToolsModalContext.Provider value={{ toggleAdminToolsModal }}>
              <div className="col-md-4 col-lg-3">
                <div className="row">
                  <AdminTools />
                  <FilterPanelNew
                    onValueChange={(
                      locationIds: string[],
                      categoryIds: string[],
                      requiresReview: boolean | null,
                      requiresWikipediaReview: boolean | null,
                      containsWikipediaDuplicates: boolean | null,
                      withoutWikipediaPages: boolean | null,
                      minSignificanceLayer: AttractionSignificanceLayer
                    ) => {
                      setCategoryIds(categoryIds)
                      setLocationIds(locationIds)
                      setRequiresReview(requiresReview)
                      setRequiresWikipediaReview(requiresWikipediaReview)

                      handleSubmit(
                        locationIds,
                        categoryIds,
                        requiresReview,
                        requiresWikipediaReview,
                        containsWikipediaDuplicates,
                        withoutWikipediaPages,
                        minSignificanceLayer,
                        northEastBound,
                        southWestBound,
                        zoomLevel
                      )
                    }}
                  />
                  <AnswersList
                    items={mapItems}
                    updateReviewedStats={updateListOfItemsReviewedStats}
                    onItemBookmarked={(index: number) => {}}
                  />
                </div>
              </div>

              <div className="col-md-8 col-lg-9">
                <div className="sticky-top">
                  <MapBoxView
                    items={mapItems}
                    reRenderMap={reRenderMap}
                    flyToCenterMap={flyToCenterMap}
                    loadPlacesOnMove={(ne, sw, zoomLevel) => {
                      setNorthEastBound(ne)
                      setSouthWestBound(sw)
                      setZoomLevel(zoomLevel)
                    }}
                    onMapMoveStart={() => {
                      setIsLoadingCircle(false)
                    }}
                  />
                  {isLoadingCircle ? (
                    <LoaderAnimationCircle isLoading={isLoadingCircle} />
                  ) : null}
                </div>
              </div>

              {showModal && (
                <AdminModalNew
                  onItemDeleted={() => handleItemDelete(modalMapItem!)}
                  onReviewStatusChanged={() => {
                    handleReviewStatusChanged(modalMapItem!)
                  }}
                  onClose={closeModal}
                  onCloseWithRefresh={onCloseWithRefresh}
                  modalMapItem={modalMapItem}
                  totalAttractionsCount={mapItems.length}
                />
              )}

              {/* {showAdminToolsModal && (
                <AdminToolsModal
                  countryId={countryId}
                  categoryId={categoryId}
                  onClose={closeAdminToolsModal}
                />
              )} */}
            </AdminToolsModalContext.Provider>
          </ModalContext.Provider>
        </MapFilterValuesContext.Provider>
      </MapHoverLinkContext.Provider>
    </div>
  )
}

export default AdminPage
