import React, { useContext, useEffect, useRef, useState } from "react"
import mapboxgl, { LngLatLike } from "mapbox-gl"
import { ModalContext } from "../Contexts/ModalContext"
import { MapHoverLinkContext } from "../Contexts/MapHoverLinkContext"
import { MapFilterValuesContext } from "../Contexts/MapFilterValuesContext"
import { AttractionNew } from "../../shared/models/AttractionNew"
import { Feature, Point, GeoJsonProperties, Geometry } from "geojson"
import { Coordinates } from "../../shared/models/Coordinates"
import { AttractionSignificanceLayer } from "../Models/Enums/AttractionSignificanceLayer"
import { log } from "console"

interface Props {
  items: AttractionNew[]
  reRenderMap: boolean
  flyToCenterMap: boolean
  loadPlacesOnMove: (
    ne: Coordinates,
    sw: Coordinates,
    zoomLevel: number
  ) => void
  onMapMoveStart: () => void
}

const MapBoxView: React.FC<Props> = ({
  items,
  reRenderMap,
  flyToCenterMap,
  loadPlacesOnMove,
  onMapMoveStart
}) => {
  const mapContainer = React.useRef<any>(null)
  const map = React.useRef<mapboxgl.Map | null>(null)
  const { toggleModal } = useContext(ModalContext)
  const { hoveredMapItem } = useContext(MapHoverLinkContext)
  const { clickedMapItem } = useContext(MapHoverLinkContext)
  const { mapFilterValues } = useContext(MapFilterValuesContext)
  const [mapLoaded, setMapLoaded] = useState<boolean>(false)

  const [searchInput, setSearchInput] = useState("")
  const markersRefWithSizes = useRef<
    { marker: mapboxgl.Marker; originalSize: number; id: string }[]
  >([])

  const removeLayerIfExists = (map: mapboxgl.Map, layerId: string) => {
    if (map.getLayer(layerId)) {
      map.removeLayer(layerId)
    }
  }

  const removeSourceIfExists = (map: mapboxgl.Map, sourceId: string) => {
    if (map.getSource(sourceId)) {
      map.removeSource(sourceId)
    }
  }

  const addSource = (
    veryHighSignificanceFeatures: Feature<Geometry, GeoJsonProperties>[],
    name: string
  ) => {
    map.current?.addSource(name, {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: veryHighSignificanceFeatures
      }
    })
  }

  const addLayer = (layerId: string, sourceId: string) => {
    map.current?.addLayer({
      id: layerId,
      type: "symbol",
      source: sourceId,
      layout: {
        "icon-image": ["get", "iconName"], // Use the iconName from the feature properties
        "icon-size": ["get", "size"], // Directly use the 'size' property value
        "icon-allow-overlap": true, // Allow icons to overlap
        "icon-ignore-placement": true // Ignore placement rules
      }
    })
  }

  const listAllLayerIds = async () => {
    setTimeout(() => {
      if (map.current) {
        const layers = map.current.getStyle().layers
        console.log("All layer ids:")
        layers.forEach(layer => {
          console.log(layer.id)
        })
      }
    }, 2000)
  }

  useEffect(() => {
    listAllLayerIds()
  }, [])

  useEffect(() => {
    if (!map.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/sierua/cm2k9oma9007l01qrdgp0golv"
      })
    }

    const currentMap = map.current

    if (currentMap == null) {
      return
    }

    currentMap.on("load", () => {
      console.log("map loaded")
      setMapLoaded(true)
    })

    if (currentMap && mapLoaded) {
      const layers = [
        "very-high-significance",
        "high-significance",
        "medium-significance",
        "low-significance",
        "minimal-significance"
      ]

      removeLayerIfExists(currentMap, "very-high-significance")
      removeLayerIfExists(currentMap, "high-significance")
      removeLayerIfExists(currentMap, "medium-significance")
      removeLayerIfExists(currentMap, "low-significance")
      removeLayerIfExists(currentMap, "minimal-significance")
      removeLayerIfExists(currentMap, "marker-labels")

      removeSourceIfExists(currentMap, "very-high-significance")
      removeSourceIfExists(currentMap, "high-significance")
      removeSourceIfExists(currentMap, "medium-significance")
      removeSourceIfExists(currentMap, "low-significance")
      removeSourceIfExists(currentMap, "minimal-significance")

      removeSourceIfExists(currentMap, "markers")

      // Prepare GeoJSON data for the markers
      const features: Feature<Geometry, GeoJsonProperties>[] = items.map(x => {
        let size = getIconSize(x)

        return {
          type: "Feature" as const,
          properties: {
            id: x.id,
            name: x.name,
            iconName: x.iconName,
            size: size,
            miniPhotoUrl: x.miniPhotoUrl,
            significanceLayer: x.significanceLayer,
            categoryRating: x.categoryRating
          },
          geometry: {
            type: "Point" as const,
            coordinates: [x.longitude, x.latitude]
          }
        }
      })

      const veryHighSignificanceFeatures = features.filter(
        f =>
          f.properties?.significanceLayer ===
          AttractionSignificanceLayer.VeryHighSignificance
      )
      const highSignificanceFeatures = features.filter(
        f =>
          f.properties?.significanceLayer ===
          AttractionSignificanceLayer.HighSignificance
      )
      const mediumSignificanceFeatures = features.filter(
        f =>
          f.properties?.significanceLayer ===
          AttractionSignificanceLayer.MediumSignificance
      )
      const lowSignificanceFeatures = features.filter(
        f =>
          f.properties?.significanceLayer ===
          AttractionSignificanceLayer.LowSignificance
      )
      const minimalSignificanceFeatures = features.filter(
        f =>
          f.properties?.significanceLayer ===
          AttractionSignificanceLayer.MinimalSignificance
      )

      addSource(minimalSignificanceFeatures, "minimal-significance")
      addSource(lowSignificanceFeatures, "low-significance")
      addSource(mediumSignificanceFeatures, "medium-significance")
      addSource(highSignificanceFeatures, "high-significance")
      addSource(veryHighSignificanceFeatures, "very-high-significance")

      addLayer("minimal-significance", "minimal-significance")
      addLayer("low-significance", "low-significance")
      addLayer("medium-significance", "medium-significance")
      addLayer("high-significance", "high-significance")
      addLayer("very-high-significance", "very-high-significance")

      map.current.moveLayer("minimal-significance", "low-significance")
      map.current.moveLayer("low-significance", "medium-significance")
      map.current.moveLayer("medium-significance", "high-significance")
      map.current.moveLayer("high-significance", "very-high-significance")

      //RenderClusters(items, map)

      map.current.addSource("markers", {
        type: "geojson",
        data: {
          type: "FeatureCollection",
          features
        }
      })

      map.current.addLayer({
        id: "marker-labels",
        type: "symbol",
        source: "markers",
        layout: {
          "text-field": ["get", "name"],
          "text-size": 12,
          "text-offset": [0, 1.25],
          "text-anchor": "top",
          visibility: "none" // Initially hide the text
        }
      })

      const popup = new mapboxgl.Popup({
        offset: 25,
        maxWidth: "400px"
      })

      layers.forEach(layer => {
        currentMap.on("mousemove", e => {
          const features = currentMap.queryRenderedFeatures(e.point, {
            layers: layers
          })
          if (features.length > 0) {
            const topFeature = features[0]
            if (
              topFeature.properties &&
              topFeature.geometry &&
              topFeature.geometry.type === "Point"
            ) {
              const coordinates = (topFeature.geometry as Point)
                .coordinates as LngLatLike
              currentMap.getCanvas().style.cursor = "pointer"
              popup
                .setLngLat(coordinates)
                .setHTML(
                  `<h6>${topFeature.properties.name}</h6><img src="${topFeature.properties.miniPhotoUrl}" alt="${topFeature.properties.name}" style="width:100%;">`
                )
                .addTo(currentMap)
            }
          } else {
            currentMap.getCanvas().style.cursor = ""
            popup.remove()
          }
        })

        currentMap.on("mouseleave", layer, () => {
          currentMap.getCanvas().style.cursor = ""
          popup.remove()
        })

        currentMap.on("click", e => {
          const features = currentMap.queryRenderedFeatures(e.point, {
            layers: layers
          })
          if (features.length > 0) {
            const topFeature = features[0]
            if (
              topFeature.properties &&
              topFeature.geometry &&
              topFeature.geometry.type === "Point"
            ) {
              const matchingItem = items.find(
                x => x.id === topFeature.properties?.id
              )
              if (matchingItem) {
                toggleModal(matchingItem)
              }
            }
          }
        })
      })

      // map.current.on("zoom", () => {
      //   if (!map.current) {
      //     return
      //   }

      //   const zoom = map.current.getZoom()
      //   if (zoom >= 1) {
      //     // Adjust the zoom level as needed
      //     setLayerVisibility(map.current, ["marker-labels"], "visible")
      //     setLayerVisibility(
      //       map.current,
      //       ["low-significance", "minimal-significance"],
      //       "visible"
      //     )
      //   } else {
      //     setLayerVisibility(map.current, ["marker-labels"], "none")
      //     setLayerVisibility(
      //       map.current,
      //       ["low-significance", "minimal-significance"],
      //       "none"
      //     )
      //   }
      // })
    }
  }, [reRenderMap, mapFilterValues])

  useEffect(() => {
    console.log("hoveredMapItem changed")

    var currentMap = map.current
    if (!currentMap) {
      return
    }

    if (hoveredMapItem) {
      animateMarkerSize(currentMap, hoveredMapItem)
    }

    if (hoveredMapItem == null) {
      removeLayerIfExists(currentMap, "hovered-marker")
      removeSourceIfExists(currentMap, "hovered-marker")
    }
  }, [hoveredMapItem])

  useEffect(() => {
    console.log("clickedMapItem changed")
    if (map.current && clickedMapItem) {
      map.current.flyTo({
        center: [clickedMapItem.longitude, clickedMapItem.latitude],
        zoom: map.current.getZoom(), // Keep the current zoom level
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion,
        speed: 1
      })
    }
  }, [clickedMapItem])

  useEffect(() => {
    if (!map.current) {
      return
    }

    map.current.on("movestart", handleMoveStart)
    map.current.on("moveend", handleMoveEnd)

    // Cleanup the event listener on component unmount
    return () => {
      if (map.current) {
        map.current.off("movestart", handleMoveStart)
        map.current.off("moveend", handleMoveEnd)
      }
    }
  }, [map])

  const setLayerVisibility = (
    map: mapboxgl.Map,
    layerIds: string[],
    visibility: "visible" | "none"
  ) => {
    layerIds.forEach(layerId => {
      map.setLayoutProperty(layerId, "visibility", visibility)
    })
  }

  const handleMoveStart = () => {
    onMapMoveStart()
  }

  const handleMoveEnd = () => {
    if (!map.current) {
      return
    }

    const bounds = map.current.getBounds()
    const zoom = map.current.getZoom()

    // Get the bounding box coordinates
    const northEast = bounds.getNorthEast()
    const southWest = bounds.getSouthWest()

    // Use these coordinates and zoom to load your places
    console.log(`NE: ${northEast.lng}, ${northEast.lat}`)
    console.log(`SW: ${southWest.lng}, ${southWest.lat}`)
    console.log(`Zoom: ${zoom}`)

    // Call your function to load places based on the bounding box and zoom level
    loadPlaces(northEast, southWest, zoom)
  }

  const loadPlaces = (
    northEast: mapboxgl.LngLat,
    southWest: mapboxgl.LngLat,
    zoom: number
  ) => {
    loadPlacesOnMove(
      { latitude: northEast.lat, longitude: northEast.lng },
      { latitude: southWest.lat, longitude: southWest.lng },
      zoom
    )
  }

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchInput(event.target.value)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      searchLocation()
    }
  }

  const searchLocation = async () => {
    const response = await fetch(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
        searchInput
      )}.json?access_token=${mapboxgl.accessToken}`
    )
    const data = await response.json()

    if (data.features.length > 0) {
      const place = data.features[0].center
      map.current!.flyTo({ center: place, zoom: 12 })
    } else {
      console.log("No results found")
    }
  }

  const animateMarkerSize = async (
    map: mapboxgl.Map,
    hoveredMapItem: AttractionNew
  ) => {
    removeLayerIfExists(map, "hovered-marker")
    removeSourceIfExists(map, "hovered-marker")

    let size = getIconSize(hoveredMapItem)

    var feature = {
      type: "Feature" as const,
      properties: {
        id: hoveredMapItem.id,
        name: hoveredMapItem.name,
        iconName: hoveredMapItem.iconName,
        size: size,
        miniPhotoUrl: hoveredMapItem.miniPhotoUrl,
        significanceLayer: hoveredMapItem.significanceLayer,
        categoryRating: hoveredMapItem.categoryRating
      },
      geometry: {
        type: "Point" as const,
        coordinates: [hoveredMapItem.longitude, hoveredMapItem.latitude]
      }
    }

    addSource([feature], "hovered-marker")
    addLayer("hovered-marker", "hovered-marker")
  }

  mapboxgl.accessToken =
    "pk.eyJ1Ijoic2llcnVhIiwiYSI6ImNsb3l0M2ViZzA2aHgybHA4Z2xjZzIzNXoifQ.NMXQZnHkhW99mJDtNiVV0A"

  return (
    <div className="">
      <div ref={mapContainer} className="map-container"></div>
      <input
        type="text"
        className="form-control map-search-input"
        placeholder="Enter a location"
        value={searchInput}
        onChange={handleSearchInputChange}
        onKeyDown={handleKeyDown}
      />
    </div>
  )
}

export default MapBoxView

function getIconSize(x: AttractionNew): number {
  let size = 1

  // Determine size based on FinalSyntheticStarScore
  if (x.countryFinalStarScore > 4.5) {
    size = 0.25
  } else if (x.countryFinalStarScore >= 4.5) {
    size = 0.22
  } else if (x.countryFinalStarScore >= 3.5) {
    size = 0.2
  } else if (x.countryFinalStarScore >= 2) {
    size = 0.18
  } else {
    // Minimal significance or lower score
    size = 0.18
  }

  return size
}

function RenderClusters(
  items: AttractionNew[],
  map: React.MutableRefObject<mapboxgl.Map | null>
) {
  if (!map.current) {
    console.log("map is not loaded yet")
    return
  }

  const features: Feature<
    Point,
    { id: string; significanceLayer: AttractionSignificanceLayer }
  >[] = items.map(item => ({
    type: "Feature",
    properties: {
      id: item.id,
      significanceLayer: item.significanceLayer
    },
    geometry: {
      type: "Point",
      coordinates: [item.longitude, item.latitude]
    }
  }))

  console.log("map loaded, can display clusters")

  if (map.current.getLayer("clusters")) {
    console.log("remove clusters")
    map.current.removeLayer("clusters")

    if (map.current.getLayer("cluster-count")) {
      map.current.removeLayer("cluster-count")
    }

    // if (map.current.getLayer("unclustered-point")) {
    //   map.current.removeLayer("unclustered-point")
    // }
    if (map.current.getSource("attractions")) {
      console.log("remove attractions")
      map.current.removeSource("attractions")
    }
  }

  //console.log("add attractions")
  //console.log(features)

  map.current.addSource("attractions", {
    type: "geojson",
    data: {
      type: "FeatureCollection",
      features
    },
    // cluster switch here:
    cluster: false,
    clusterMaxZoom: 7, // Max zoom to cluster points on
    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
  })

  map.current.addLayer({
    id: "clusters",
    type: "circle",
    source: "attractions",
    filter: ["has", "point_count"],
    paint: {
      // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
      // with three steps to implement three types of circles:
      //   * Blue, 20px circles when point count is less than 100
      //   * Yellow, 30px circles when point count is between 100 and 750
      //   * Pink, 40px circles when point count is greater than or equal to 750
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#51bbd6",
        100,
        "#f1f075",
        750,
        "#f28cb1"
      ],
      "circle-radius": ["step", ["get", "point_count"], 20, 100, 30, 750, 40]
    }
  })

  map.current.addLayer({
    id: "cluster-count",
    type: "symbol",
    source: "attractions",
    filter: ["has", "point_count"],
    layout: {
      "text-field": ["get", "point_count_abbreviated"],
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12
    }
  })

  map.current.moveLayer("clusters")
  map.current.moveLayer("cluster-count")
}
