import axios, { AxiosInstance } from "axios"

interface CustomAxiosInstance extends AxiosInstance {
  setAuthToken: (token: string | null) => void
}

const api: CustomAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT + `api/`
}) as CustomAxiosInstance

api.setAuthToken = (token: string | null) => {
  if (token) {
    api.defaults.headers.common["Authorization"] = `Bearer ${token}`
    localStorage.setItem("jwtToken", token)
  } else {
    delete api.defaults.headers.common["Authorization"]
    localStorage.removeItem("jwtToken")
  }
}

export default api
