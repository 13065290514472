import MainPage from "./Components/MainPage"
import AdminPage from "./Components/AdminPage"
import { GoogleOAuthProvider } from "@react-oauth/google"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import "./App.css"
import ProtectedRoute from "./Components/ProtectedRoute"
import { AuthProvider } from "./Contexts/AuthContext"
import AuthenticatedLayout from "./Components/AuthenticatedLayout"
import LoginPage from "./Components/LoginPage"
import LogViewer from "./Components/LogViewer"
import AdminPageNew from "./Components/AdminPageNew"

function App() {
  const clientId: string = process.env.REACT_APP_GOOGLE_CLIENT_ID || ""

  return (
    <Router>
      <GoogleOAuthProvider clientId={clientId}>
        <AuthProvider>
          <Routes>
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <MainPage />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/admin"
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <AdminPageNew />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              }
            />
            <Route
              path="/logs"
              element={
                <ProtectedRoute>
                  <AuthenticatedLayout>
                    <LogViewer />
                  </AuthenticatedLayout>
                </ProtectedRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </AuthProvider>
      </GoogleOAuthProvider>
    </Router>
  )
}

export default App
