import React from "react"

interface FooterProps {
  companyName: string
}

const Footer: React.FC<FooterProps> = ({ companyName }) => {
  return (
    <footer className="bg-white py-4 mt-auto">
      <p className="m-0">
        &copy; {companyName} {new Date().getFullYear()}
      </p>
    </footer>
  )
}

export default Footer
