import React, { useEffect, useState } from "react"
import { PhotoModel } from "../../shared/models/PhotoModel"
import api from "../Api/Api"

interface AdminPhotoGalleryProps {
  imageUrls: PhotoModel[]
}

const AdminPhotoGallery: React.FC<AdminPhotoGalleryProps> = ({ imageUrls }) => {
  const [photos, setPhotos] = useState<PhotoModel[]>(imageUrls)

  useEffect(() => {
    setPhotos(imageUrls)
  }, [imageUrls])

  const handleRemove = (id: string) => {
    console.log("Remove:", id)
    api.delete(`/admin/photo/${id}`).then(response => {
      console.log(response.data)
      if (response.status === 200) {
        alert("Photo removed")
        setPhotos(photos.filter(photo => photo.id !== id))
      }
    })
  }

  const handleSetAsMain = (id: string) => {
    console.log("Set as main:", id)
    api.post(`/admin/photo/setMain/${id}`).then(response => {
      console.log(response.data)
      if (response.status === 200) {
        alert("Photo set as main")
        const updatedPhotos = photos.map(photo => {
          if (photo.id === id) {
            return { ...photo, isMainPhoto: true }
          } else {
            return { ...photo, isMainPhoto: false }
          }
        })

        // Update the state with the new array
        setPhotos(updatedPhotos)
      }
    })
  }

  return (
    <p>
      <div className="container">
        <div className="row">
          {photos.map((photo, index) => (
            <div
              className="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"
              key={photo.id}
            >
              <img
                src={`${process.env.REACT_APP_API_ENDPOINT}${photo.url}`}
                alt={`Gallery ${index}`}
                className={`img-fluid ${
                  photo.isMainPhoto ? "main-photo-img" : ""
                }`}
              />
              <div className="d-flex justify-content-between mt-2">
                <button
                  className="btn btn-danger btn-sm"
                  onClick={() => handleRemove(photo.id)}
                >
                  Remove
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleSetAsMain(photo.id)}
                >
                  Set as main
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </p>
  )
}

export default AdminPhotoGallery
