import React, { useEffect, useState } from "react"
import api from "../Api/Api"
import { FilterDataNew } from "../Models/Query/FilterDataNew"
import { Category } from "../Models/Query/Category"
import CheckboxTree from "react-checkbox-tree"
import "react-checkbox-tree/lib/react-checkbox-tree.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
import { CheckboxTreeNode } from "../Models/Query/CheckboxTreeNode"
import { AreaLocation } from "../Models/Query/AreaLocation"
import { AttractionSignificanceLayer } from "../Models/Enums/AttractionSignificanceLayer"
import { useFilterViewStore } from "../Stores/filterViewStore"

interface Props {
  onValueChange: (
    locationIds: string[],
    categoryIds: string[],
    requiresReview: boolean | null,
    requiresWikipediaReview: boolean | null,
    containsWikipediaDuplicates: boolean | null,
    withoutWikipediaPages: boolean | null,
    minSignificanceLayer: AttractionSignificanceLayer
  ) => void
}

const FilterPanel: React.FC<Props> = ({ onValueChange }) => {
  const [category, setCategory] = useState<Category>({
    id: "",
    name: "",
    childCategories: []
  })

  const [location, setLocation] = useState<AreaLocation>({
    id: "",
    name: "",
    childLocations: []
  })

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [filterData, setFilterData] = useState<FilterDataNew>({
    locations: [],
    categories: []
  })

  const [requiresWikipediaReview, setRequiresWikipediaReview] = useState<
    boolean | null
  >(null)

  const [requiresReview, setRequiresReview] = useState<boolean | null>(null)

  const [containsWikipediaDuplicates, setContainsWikipediaDuplicates] =
    useState<boolean | null>(null)

  const [withoutWikipediaPages, setWithoutWikipediaPages] = useState<
    boolean | null
  >(null)

  const [checkedCategories, setCheckedCategories] = useState<string[]>([])
  const [expandedCategories, setExpandedCategories] = useState<string[]>([])

  const [checkedLocations, setCheckedLocations] = useState<string[]>([])
  const [expandedLocations, setExpandedLocations] = useState<string[]>([])

  const [minSignificanceLayer, setMinSignificanceLayer] =
    useState<AttractionSignificanceLayer>(
      AttractionSignificanceLayer.MinimalSignificance
    )

  const {
    isFreeMapSearch,
    setIsFreeMapSearch,
    numberOfItemsToLoad,
    setNumberOfItemsToLoad,
    isFeaturedItem,
    setIsFeaturedItem,
    isHiddenGem,
    setIsHiddenGem
  } = useFilterViewStore(state => ({
    isFreeMapSearch: state.isFreeMapSearch,
    setIsFreeMapSearch: state.setIsFreeMapSearch,
    numberOfItemsToLoad: state.numberOfItemsToLoad,
    setNumberOfItemsToLoad: state.setNumberOfItemsToLoad,
    isFeaturedItem: state.isFeaturedItem,
    setIsFeaturedItem: state.setIsFeaturedItem,
    isHiddenGem: state.isHiddenGem,
    setIsHiddenGem: state.setIsHiddenGem
  }))

  const handleRequiresWikipediaReviewChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequiresWikipediaReview(e.target.checked)
  }

  const handleRequiresReviewChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRequiresReview(e.target.checked)
  }

  const handleContainsWikipediaDuplicates = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setContainsWikipediaDuplicates(e.target.checked)
  }

  const handleWithoutWikipediaPages = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setWithoutWikipediaPages(e.target.checked)
  }

  const handlMinSignificanceLayerChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setMinSignificanceLayer(e.target.value as AttractionSignificanceLayer)
  }

  const mapCategoriesToNodes = (categories: Category[]): CheckboxTreeNode[] => {
    return categories.map(category => {
      const node: CheckboxTreeNode = {
        label: category.name,
        value: category.id,
        children: undefined
      }

      if (category.childCategories.length > 0) {
        node.children = mapCategoriesToNodes(category.childCategories)
      }

      return node
    })
  }

  const mapLocationsToNodes = (
    locations: AreaLocation[]
  ): CheckboxTreeNode[] => {
    return locations.map(location => {
      const node: CheckboxTreeNode = {
        label: location.name,
        value: location.id,
        children: undefined
      }

      if (location.childLocations.length > 0) {
        node.children = mapLocationsToNodes(location.childLocations)
      }

      return node
    })
  }

  const handleSubmit = () => {
    onValueChange(
      checkedLocations,
      checkedCategories,
      requiresReview,
      requiresWikipediaReview,
      containsWikipediaDuplicates,
      withoutWikipediaPages,
      minSignificanceLayer
    )
  }
  useEffect(() => {
    api
      .get<FilterDataNew>(`/admin/filterDataNew`)
      .then(response => {
        setFilterData(response.data)
        setLocation(response.data.locations[0])
        setCategory(response.data.categories[0])
        console.log(response.data)
        console.log(filterData)
      })
      .catch(error => {
        console.error(error)
        alert(error)
      })
  }, [])

  return (
    <div className="col-md-12">
      <br />
      <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <h3 className="text-center display-10">
          <span className="">Filter by</span>
        </h3>

        <label>Country</label>

        <div>
          <CheckboxTree
            nodes={mapLocationsToNodes(filterData.locations)}
            checked={checkedLocations}
            expanded={expandedLocations}
            onCheck={checked => setCheckedLocations(checked)}
            onExpand={expanded => setExpandedLocations(expanded)}
          />
        </div>

        <label>Category</label>

        <div>
          <CheckboxTree
            nodes={mapCategoriesToNodes(filterData.categories)}
            checked={checkedCategories}
            expanded={expandedCategories}
            onCheck={checked => setCheckedCategories(checked)}
            onExpand={expanded => setExpandedCategories(expanded)}
          />
        </div>

        <label>Minimum significance Layer</label>
        <select
          value={minSignificanceLayer}
          onChange={handlMinSignificanceLayerChange}
          className="form-select"
          aria-label="Significance layer select"
        >
          {Object.values(AttractionSignificanceLayer).map(layer => (
            <option key={layer} value={layer}>
              {layer}
            </option>
          ))}
        </select>
        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isFreeMapSearch}
            onChange={() => setIsFreeMapSearch(!isFreeMapSearch)}
            id="free-map-search-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="free-map-search-checkbox"
          >
            Free search on map as you move it
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isFeaturedItem}
            onChange={() => setIsFeaturedItem(!isFeaturedItem)}
            id="featured-item-checkbox"
          />
          <label className="form-check-label" htmlFor="featured-item-checkbox">
            Only featured items
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={isHiddenGem}
            onChange={() => setIsHiddenGem(!isHiddenGem)}
            id="hidden-gem-checkbox"
          />
          <label className="form-check-label" htmlFor="hidden-gem-checkbox">
            Only hidden gems
          </label>
        </div>

        <div className="form-group">
          <label htmlFor="number-of-items-input">Number of items to load</label>
          <input
            className="form-control"
            type="number"
            value={numberOfItemsToLoad}
            onChange={e => setNumberOfItemsToLoad(Number(e.target.value))} // Replace with the setter function for the state
            id="number-of-items-input"
            min="1"
            step="1"
          />
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={requiresWikipediaReview ?? false}
            onChange={handleRequiresWikipediaReviewChange}
            id="wikipedia-review-checkbox"
          />
          <label
            className="form-check-label"
            htmlFor="wikipedia-review-checkbox"
          >
            Requires Wikipedia review
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={requiresReview ?? false}
            onChange={handleRequiresReviewChange}
            id="reviews-checkbox"
          />
          <label className="form-check-label" htmlFor="reviews-checkbox">
            Requires review
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={containsWikipediaDuplicates ?? false}
            onChange={handleContainsWikipediaDuplicates}
            id="wikipedia-contains-duplicates"
          />
          <label
            className="form-check-label"
            htmlFor="wikipedia-contains-duplicates"
          >
            Contains Wikipedia duplicates
          </label>
        </div>

        <div className="form-check">
          <input
            className="form-check-input"
            type="checkbox"
            checked={withoutWikipediaPages ?? false}
            onChange={handleWithoutWikipediaPages}
            id="wikipedia-without-pages"
          />
          <label className="form-check-label" htmlFor="wikipedia-without-pages">
            Without Wikipedia pages
          </label>
        </div>

        <button
          className="btn btn-outline-primary"
          onClick={handleSubmit}
          style={{ marginTop: "10px" }}
        >
          Submit
        </button>
      </div>
    </div>
  )
}

export default FilterPanel
