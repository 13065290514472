import React, { useEffect } from "react"
import { useState } from "react"
import api from "../Api/Api"
import { Attraction } from "../Models/Attraction"
import { Query } from "../Models/Query"
import { QueryBuilder } from "../Models/QueryBuilder"
import AnswersList from "./AnswersList"
import DescriptionModal from "./DescriptionModal"
import LoaderAnimation from "./LoaderAnimation"
import MapView from "./MapView"
import TextDropdown from "./TextDropdown"
import { ModalContext } from "../Contexts/ModalContext"
import { MapHoverLinkContext } from "../Contexts/MapHoverLinkContext"
import "bootstrap-icons/font/bootstrap-icons.css"
import Bookmarks from "./Bookmarks"

const MainPage: React.FC = () => {
  const [mapItems, setData] = useState<Attraction[]>([])
  const [queryBuilder, setQueryBuilder] = useState<QueryBuilder>()
  const [programmaticQuery, setProgrammaticQuery] = useState<Query>({
    number: "",
    place: "",
    placeCharacteristic: "",
    placeType: "",
    sortType: "",
    topLast: ""
  })
  const [query, setQuery] = useState<string>("")
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showModal, setShowModal] = useState(false)
  const [modalMapItem, setModalMapItem] = useState<Attraction | null>(null)
  const [hoveredMapItem, setHoveredMapItem] = useState<Attraction | null>(null)
  const [clickedMapItem, setClickedMapItem] = useState<Attraction | null>(null)
  const [reRenderMap, setReRenderMap] = useState<boolean>(false)
  const [updateListOfItemsReviewedStats, setUpdateListOfItemsReviewedStats] =
    useState<boolean>(false)

  const toggleModal = (mapItem: Attraction | null) => {
    setModalMapItem(mapItem)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setModalMapItem(null)
  }

  useEffect(() => {
    api
      .get<QueryBuilder>(`/data/queryBuilder`)
      .then(response => {
        setQueryBuilder(response.data)
        initProgrammaticQuery(response.data)
      })
      .catch(error => {
        console.error(error)
        alert(error)
      })
  }, [])

  const loadMap = () => {
    let queryToSend = `topLast=${programmaticQuery.topLast}
    &number=${programmaticQuery.number}
    &placeCharacteristic=${programmaticQuery.placeCharacteristic}
    &placeType=${programmaticQuery.placeType}
    &place=${programmaticQuery.place}
    &sortType=${programmaticQuery.sortType}`

    console.log(queryToSend)

    setIsLoading(true)
    api
      .get<Attraction[]>(`/data?${queryToSend}`)
      .then(response => {
        SetItems(response.data)
        setReRenderMap(!reRenderMap)
        setIsLoading(false)
      })
      .catch(error => {
        console.error(error)
        alert(error)
        setIsLoading(false)
      })
  }

  const SetItems = (items: Attraction[]) => {
    var bookmarkedItems = mapItems.filter(x => x.bookmarked)
    var bookmarkedItemsToAdd: Attraction[] = []

    for (let i = 0; i < bookmarkedItems.length; i++) {
      const x = bookmarkedItems[i]
      let shouldAdd = true

      for (let j = 0; j < items.length; j++) {
        if (items[j].id === x.id) {
          shouldAdd = false
          break
        }
      }

      if (shouldAdd) {
        bookmarkedItemsToAdd.push(x)
      }
    }

    bookmarkedItemsToAdd.forEach(x => items.push(x))
    setData(items)
  }

  function handleQueryChange(event: React.ChangeEvent<HTMLInputElement>) {
    setQuery(event.target.value)
  }

  function handleShowModal() {
    setShowModal(true)
  }

  function initProgrammaticQuery(response: QueryBuilder) {
    setProgrammaticQuery({
      ...programmaticQuery,
      number: response.number[0],
      place: response.place[0],
      placeCharacteristic: response.placeCharacteristic[0],
      placeType: response.placeType[0],
      sortType: response.sortType[0],
      topLast: response.topLast[0]
    })
  }

  const handleQueryItemChange = (value: string, field: string) => {
    console.log(field, " : ", value)
    setProgrammaticQuery({ ...programmaticQuery, [field]: value })
    //setProgrammaticQuery()
    //setPublicProperty(value);
  }

  const onItemBookmarked = (index: number) => {
    let i = 0
    const newState = mapItems.map(item => {
      if (i++ === index) {
        return { ...item, bookmarked: !item.bookmarked }
      } else {
        return item
      }
    })
    console.log(newState)
    setData(newState)
  }

  return (
    <div className="text-center">
      <div className="text-center">
        <h1 className="display-4 mt-4 fw-bolder">
          <span className="text-gradient">AI Maps</span>
        </h1>
      </div>

      <div className="container mt-3">
        <div className="row justify-content-center">
          <div className="col-sm-12">
            <div className="input-group search-box px-3 justify-content-center">
              <i className="bi bi-search search-icon"></i>
              {queryBuilder != null ? (
                <div className="d-flex align-items-center fs-4">
                  <TextDropdown
                    items={queryBuilder.topLast}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "topLast")
                    }
                  />
                  <TextDropdown
                    items={queryBuilder.number}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "number")
                    }
                  />
                  <TextDropdown
                    items={queryBuilder.placeCharacteristic}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "placeCharacteristic")
                    }
                  />
                  <TextDropdown
                    items={queryBuilder.placeType}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "placeType")
                    }
                  />

                  <span>in</span>

                  <TextDropdown
                    items={queryBuilder.place}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "place")
                    }
                  />

                  <span>by</span>

                  <TextDropdown
                    items={queryBuilder.sortType}
                    onValueChange={(value: string) =>
                      handleQueryItemChange(value, "sortType")
                    }
                  />
                </div>
              ) : null}

              {
                <div className="input-group-append">
                  <button
                    className="btn btn-primary m-2"
                    onClick={loadMap}
                    type="button"
                  >
                    Create!
                  </button>
                </div>
              }
            </div>
          </div>
        </div>
        <br />
      </div>

      {/* <MapHoverLinkContext.Provider
        value={{
          hoveredMapItem,
          setHoveredMapItem,
          clickedMapItem,
          setClickedMapItem
        }}
      >
        <ModalContext.Provider value={{ showModal, modalMapItem, toggleModal }}>
          {showModal && (
            <DescriptionModal
              modalMapItem={modalMapItem}
              onClose={closeModal}
            />
          )}

          {isLoading ? <LoaderAnimation /> : null}
          {mapItems.length > 0 ? (
            <div className="row justify-content-center">
              <AnswersList
                items={mapItems}
                updateReviewedStats={updateListOfItemsReviewedStats}
                onItemBookmarked={onItemBookmarked}
              />
              <MapView items={mapItems} reRenderMap={reRenderMap} />

              {mapItems.some(x => x.bookmarked) ? (
                <Bookmarks items={mapItems} />
              ) : null}
            </div>
          ) : null}

          {mapItems.length > 0 ? <div className="row"></div> : null}
        </ModalContext.Provider>
      </MapHoverLinkContext.Provider> */}
    </div>
  )
}

export default MainPage
