import React, { useContext } from "react"
import { AdminToolsModalContext } from "../Contexts/AdminToolsModalContext"

const AdminTools: React.FC = () => {
  const handleSubmit = () => {
    toggleAdminToolsModal()
  }

  const { toggleAdminToolsModal } = useContext(AdminToolsModalContext)

  return (
    <div className="col-md-12">
      <h3 className="text-center display-10">
        <span className="">Tools</span>
      </h3>

      <button
        className="btn btn-outline-primary"
        onClick={handleSubmit}
        style={{ marginTop: "10px" }}
      >
        Open tools
      </button>
    </div>
  )
}

export default AdminTools
